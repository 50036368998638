import React,{useState} from 'react';

import { images } from '../../constants';
import './AboutUs.css';

function AboutUs(){
  const [show,setShow]=useState(false)
  const [showed,setShowed]=useState(false)




// const AboutUs = () => (
  return (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-overlay flex__center">
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">About Us</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">Nadiguttu is located at Maroor, which is 8 km from Moodabidri. We have been celebrating festivals for more than 500 years. </p>
        {
       show? <p className="p__opensans">We celebrate festivals such as Ganesh Chaturthi, Nagarpanchami, Mahalaya Amavasya, and Duserra as well as the ceremony of Parba with our Daivas on Nadiguttu's mainland.</p>:null
     }
        <button type="button" className="custom__button" onClick={() => setShow(!show)}>Know More</button>
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.daiva} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Our History</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">During the time of 1522, our ancestors built the house of Nadiguttu for the betterment of future generations and to have a better place to live.</p>
        {
       showed? <p className="p__opensans">In such a situation, our ancestors offered poojas and rituals to gods and daivas, from which the culture and practice have been passed on to the upcoming generations.</p>:null
     }
        <button type="button" className="custom__button" onClick={() => setShowed(!showed)}>Know More</button>
      </div>
    </div>
  </div>
);
    }
export default AboutUs;