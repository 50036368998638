import images from './images';

const awards = [
  {
    imgUrl: images.award01,
    title: 'Presence',
    subtitle: 'As part of the entity, all of us attended the auspicious ceremony in the presence of lord hosangady Gopal Krishna and kodmanithaya.',
  },
  {
    imgUrl: images.award02,
    title: 'Value',
    subtitle: 'The value of Nadiguttu lies in its ability to achieve trust.',
  },
  {
    imgUrl: images.award03,
    title: 'Hospitality',
    subtitle: 'Hospitality has always been an integral part of nadiguttu.',
  }, 
];

export default {awards };
