import bg from '../assets/bg.png';
import chef1 from '../assets/chef1.png';
import gallery01 from '../assets/gallery01.JPG';
import gallery02 from '../assets/gallery02.JPG';
import gallery03 from '../assets/gallery03.JPG';
import gallery04 from '../assets/gallery04.JPG';
import gallery05 from '../assets/gallery05.JPG';
import daiva from '../assets/daiva.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome1 from '../assets/welcome1.png';
import laurels2 from '../assets/laurels2.jpg';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award04 from '../assets/award04.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import maroorlogo from '../assets/maroorlogo.png';

export default {
  bg,
  chef1,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  daiva,
  overlaybg,
  spoon,
  welcome1,
  laurels2,
  award01,
  award02,
  award03,
  award04,  
  award05,
  sign,
  quote,
  maroorlogo,
};
