import React,{useState, useEffect} from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => {
   const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (clicked) {
      window.location.assign('https://www.google.co.in/maps/place/Maroor+Nadiguttu/@13.0510431,75.05662,17z/data=!3m1!4b1!4m5!3m4!1s0x3ba4abda9e6da79f:0x7bc449e0733b0521!8m2!3d13.0510279!4d75.0587292');
    }
  });
return(
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="Chase the new dreams" />
      <h1 className="app__header-h1">Maroor Bhandarkar's</h1>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>have been located alongside Moodabidri in Dakshina Kannada and have celebrated diverse festivals for centuries.</p>
      <button type="button" className="custom__button"  onClick={() => setClicked(true)}>Explore</button>
    </div>

    <div className="app__wrapper_img">
      <img src={images.welcome1} alt="header_img" />
    </div>
  </div>
);
}

export default Header;
