import React, {useState} from 'react';
import styled from 'styled-components';
import SubHeading from '../SubHeading/SubHeading';
import './Newsletter.css';
import db from '../../firebase';
import firebase from 'firebase/compat/app';


function Newsletter(){
  const [input, setInput] = useState("");
  const [message, setMessage] = useState("");
  const inputHandler = (e) => {
    setInput(e.target.value);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (input) {
      // add to firebase
      db.collection("email").add({
        email: input,
        time: firebase.firestore.FieldValue.serverTimestamp(),
      });
      setInput("");
      setMessage("Thank you for Subscribing!!!");
      setTimeout(() => {
        setMessage("");
      }, 5000);
    }
  };
return (
  <div className="app__newsletter">
    <div className="app__newsletter-heading">
      <SubHeading title="Newsletter" />
      <h1 className="headtext__cormorant">Subscribe To Our Updates</h1>
      <p className="p__opensans">And never miss latest Updates!</p>
    </div>
    <div className="app__newsletter-input flex__center">
      <input type="email" placeholder="Enter your email address" onChange={inputHandler} value={input}/>
      <button type="button" className="custom__button" onClick={submitHandler}>Subscribe</button>
      {message && <Alert>{message}</Alert>}
    </div>
  </div>
)
}
// Glassmorphism UI
const Alert = styled.p`
  position: relative;
  padding: 0.4rem;
  margin: 0.5rem;
  color: var(--color-golden);
  text-align: center;
  font-size: 1.2rem;
  font-family: var(--font-base);
  /* border: 1px solid var(--color-golden); */
  border-radius: 3px;
  background: var(--color-black);    
  backdrop-filter: blur(10px);
  z-index: 3;
`;

export default Newsletter;