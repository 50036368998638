import React from 'react';

import { AboutUs, Chef, Footer, Gallery, Header, Laurels } from './container';
import { Navbar } from './components';
import './App.css';

const App = () => (
  <div>
    <Navbar />
    <Header />
    <AboutUs />
    <Chef />
    {/* <Intro /> */}
    <Laurels />
    <Gallery />
    <Footer />


    <div>
    <a
        href="https://wa.me/message/RBI7NX5L5SJEE1"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </div>
  </div>
);

export default App;
