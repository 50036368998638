import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCfJPtd81mZURY7res3WgDFY59JVT4MICQ",
    authDomain: "bhandarkar-family-trust.firebaseapp.com",
    projectId: "bhandarkar-family-trust",
    storageBucket: "bhandarkar-family-trust.appspot.com",
    messagingSenderId: "1055512717436",
    appId: "1:1055512717436:web:2e0479bd2ad63a294bc1f9",
    measurementId: "G-7D87Y35QSP"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
export default db;